@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';
@import '~react-datetime/css/react-datetime.css';

:root {
  --disponibilidadColor: gainsboro;
  --citaColor: #feed00;
  --successColor: #49b02c;
  --errorColor: #d80000;
  --niceOrange: #FFB69A;
  --nicePink: #FFC4A8;
  --niceAquamarina: #00BB7A;
  --niceAquamarina2: #00968D;
}

@keyframes colorchange {
  0%   {
    background: var(--citaColor);
    border-color: var(--citaColor);
  }
  50% {
    background: var(--niceOrange);
    border-color: var(--niceOrange);
  }
  100%   {
    background: var(--citaColor);
    border-color: var(--citaColor);
  }
}


.rbc-calendar {
  width: 100%;
  min-height: 70vh;
}
.rbc-current-time-indicator { display: none; }
.force-overflow { overflow: visible; }
.distHorizontal { > * + * { margin-left: 1em; } }
.distVertical { > * + * { margin-top: 1em; } }
.rbc-allday-cell { display: none; }
.rbc-time-content { border-width: 1px; }
.MuiFormControl-root,
.MuiBox-root { width: 100%; }
.MuiSelect-root { min-width: 120px; }
.rdtPicker { width: 100%; }

.FullContainer {
  padding: 0 !important;
  height: 100%;
  width: 100vw;
  > .MuiBox-root { padding: 24px !important; }
  // .MuiPaper-root { padding: 0 !important; }
}
.rbc-addons-dnd-resize-ns-icon { height: 7px; }
// .alien { pointer-events: none; }
.checkbox .MuiFormControlLabel-root { margin-right: 0 !important }

.rbc-event.event-lock {
  // TODO: define w/ Palombus
  display: none;
}
.CalEspecialista {
  .alien {
    max-width: 60%;
    opacity: .8;
    &:not(.cita) { cursor: not-allowed; }
    .rbc-addons-dnd-resize-ns-anchor { pointer-events: none; }
    .rbc-addons-dnd-resize-ns-icon { display: none !important; }
  }

  .native + .alien {
    opacity: .3;
    pointer-events: none;
  }
}
[role="dialog"] .MuiTableHead-root { display: none; }
.MuiDialog-root ~ .MuiDialog-root .MuiPaper-root { max-width: 300px }
.babyCita {
  &:after {
    content: '👶';
    position: absolute;
    bottom: 2px;
    left: 5px;
  }
}

.myTurn {
  animation: colorchange 1s infinite;
  &:after {
    content: '❗';
    position: absolute;
    bottom: 2px;
    right: 5px;
    font-size: 250%;
  }
}


// Colores!
.rbc-event {
  position: relative;
  &.disponibilidad {
    background-color: var(--disponibilidadColor) !important;
    border-color: var(--disponibilidadColor) !important;
    color: rgba(0,0,0,.7);
  }

  &.cita {
    background-color: var(--citaColor) !important;
    border-color: var(--citaColor) !important;
    color: rgba(0,0,0,.7);

    .rbc-addons-dnd-resize-ns-anchor { display: none; }
  }

  &.rechazada {
    background-color: var(--errorColor) !important;
    border-color: var(--errorColor) !important;
    color: white;
  }

  &.aceptada {
    background-color: var(--nicePink) !important;
    border-color: var(--nicePink) !important;
    color: rgba(0,0,0,.7);
  }

  &.confirmada {
    background-color: var(--successColor) !important;
    border-color: var(--successColor) !important;
    color: rgba(0,0,0,.7);
  }

  &:hover {
    filter: brightness(103%)
  }
}

.hide {
  display: none !important;
}
